import datatables_initialize from "../datatables_initialize";
import yadcf_wrapper from "../yadcf_wrapper";
import date_sort from "../date_sort";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    super.connect();

    const tableElement = $(this.element).find("table.dataTable");
    const tableheaders = Array.from(this.element.querySelectorAll("table.dataTable th"));

    if (!tableElement || tableElement.length == 0) {
      console.warn("DataTable not found!");
      return;
    }

    const dataTable = datatables_initialize(tableElement);

    for (var i = 0; i < tableheaders.length; i++) {
      if (tableheaders[i].dataset.hidden == "true") {
        dataTable.column(i).visible(false);
      }
    }

    dataTable.columns.adjust().draw();

    if (tableElement.hasClass("excel-export")) {
      const buttons = new $.fn.dataTable.Buttons(dataTable, {
        buttons: [
          {
            extend: "excel",
            exportOptions: {
              format: {
                header: function (data, _columnIdx, _node) {
                  return data.replace(/<div.*$/gi, "");
                },
                body: function (data, rowIdx, columnIdx, node) {
                  if (columnIdx === 9 || columnIdx === 10 || columnIdx === 11) {
                    return node.innerText;
                  } else if (columnIdx === 26) {
                    return "";
                  } else {
                    return data;
                  }
                },
              },
            },
          },
        ],
      });

      buttons
        .container()
        .find("button")
        .css("float", "right")
        .css("margin", "0px 8px")
        .insertBefore("div.page-header > h1");
    }

    const filters = tableheaders.map((th) => {
      if ((th.dataset === undefined) || (th.dataset["yadcf"] === undefined)) {
        return {}
      }
      return JSON.parse(th.dataset["yadcf"])
    })
    yadcf_wrapper(dataTable, filters);
  }
}
